<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    let itemDetails = {};

    function setItemDetails() {
        // Extracting necessary properties directly from the item's id
        const {vanityType, no, avatarId, doubleSided, side} = getItemProperties(item.id);

        itemDetails.title = $_(`${vanityType}Side${side || 1}Avatar${avatarId}Title`);
        itemDetails.description = doubleSided
            ? $_(`${vanityType}Side${side || 1}Avatar${avatarId}Description`)
            : $_('SeasonalAvatarDescription');
        itemDetails.amount = item.amount;
        itemDetails.icon = `chat-avatar-${vanityType.toLowerCase()}-side${side || 1}-avatar${avatarId || no}`;
        itemDetails.rarity = 4;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        ;
        itemDetails.claimed = item.claimed; // Assuming this property exists
    }

    function getItemProperties(id) {
        let properties = {
            vanityType: 'Default',
            no: 1,
            avatarId: id,
            doubleSided: false,
            side: 1
        };

        switch (id.toString()) {
            case "624":
            case "626":
                properties = {...properties, vanityType: 'StPatrick', no: 1, avatarId: 8};
                break;
            case "625":
            case "627":
                properties = {...properties, vanityType: 'StPatrick', no: 2, avatarId: 9};
                break;
            case "2176":
                properties = {...properties, vanityType: 'Wof', no: 0, avatarId: 15, doubleSided: true, side: 3};
                break;
            case "2182":
                properties = {...properties, vanityType: 'Easter', no: 0, avatarId: 16, doubleSided: true, side: 3};
                break;
            case "2197":
                properties = {...properties, vanityType: '4thofjuly', no: 0, avatarId: 17, doubleSided: true, side: 3};
                break;
            case "2200":
                properties = {...properties, vanityType: 'FathersDay', no: 0, avatarId: 18, doubleSided: true, side: 3};
                break;
            case "2202":
                properties = {...properties, vanityType: 'Default', no: 0, avatarId: 19, doubleSided: true, side: 3};
                break;
            case "2223":
                properties = {...properties, vanityType: 'Halloween', no: 0, avatarId: 21, doubleSided: true, side: 3};
                break;
            case "2227":
                properties = {...properties, vanityType: 'November', no: 0, avatarId: 22, doubleSided: true, side: 3};
                break;
            case "2240":
                properties = {...properties, vanityType: 'December', no: 0, avatarId: 24, doubleSided: true, side: 3};
                break;
            case "2290":
                properties = {...properties, vanityType: 'Redhead', no: 0, avatarId: 25, doubleSided: true, side: 3};
                break;
            case "2292":
                properties = {...properties, vanityType: 'Mother', no: 0, avatarId: 26, doubleSided: true, side: 3};
                break;
            case "2298":
                properties = {...properties, vanityType: 'Spring', no: 0, avatarId: 27, doubleSided: true, side: 3};
                break;
        }

        return properties;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>