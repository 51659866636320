<script>
    import {_, locale, number} from 'svelte-i18n';
    import {createEventDispatcher} from 'svelte';
    import Ribbon from '../lib/Ribbon.svelte';
    import Starburst from '../lib/Starburst.svelte';
    import Countdown from '../lib/Countdown.svelte';
    import {gameConfig, selectedCurrency, user, giftFriend, luckyDrawEvent} from '../stores';
    import {getStorePackConfig} from '../yaml';
    import {Button} from 'svelte-chota';
    import {amazon_pack_image_url, game_name} from '../constants';
    import ItemsListing from './ItemsListing.svelte';
    import {getPackRarity, resourceDetails} from '../globals';
    import giftPackIcon from '../assets/gift_friend/gift_pack_icon.png?format=webp;jpg';
    import giftPackWinterIcon from '../assets/gift_friend/winter_gift_pack_icon.png?format=webp;jpg';

    export let storePack = {}
    export let storeSection = '';
    export let globals = {};

    const dispatch = createEventDispatcher();

    let packDetails = null;
    // rarity: 1 = common, 2 = rare, 3 = epic, 4 = legendary
    let rarity = storePack.rarity;
    let goldBonus = storePack.goldBonus || '';
    let goldWorth = storePack.goldWorth || '';
    let goldAmount =
        storeSection === 'subscriptions'
            ? storePack.dailyRewards.gold
            : storePack.gold || '';
    let items =
        storeSection === 'subscriptions'
            ? storePack.dailyRewards.items
            : storeSection === 'giftFriend'
                ? [...storePack.itemsForAFriend, ...storePack.items]
                : storePack.items || [];
    let giftPackIconUrl = giftPackIcon;
    let giftPackWinterIconUrl = giftPackWinterIcon;

    const priceString = storePack.paymentHub.price.amount !== 'missing' && storePack.paymentHub.price.currency !== 'missing'
        ? $number(storePack.paymentHub.price.amount / 100, {
            style: 'currency',
            currency: storePack.paymentHub.price.currency,
        })
        : 'Price not available';

    function getUnityPackConfig() {
        if (storePack.bundleName !== undefined) {
            return getStorePackConfig(
                amazon_pack_image_url + '/' + storePack.bundleName + '/config.asset',
            );
        }
    }

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }

    async function getPromoPackDetails() {
        let details = {};

        await getUnityPackConfig().then((config) => {
            if (config === undefined) {
                Rollbar.error('No config found for pack: ' + storePack.bundleName);
                return;
            }

            if (config['hasSide'] === 0) {
                details.title = config['specialTitleKey'];
                // details.description = config['specialTitleDescriptionKey'];
                details.imageSide = '';
            } else {
                if ($user.user.side === 1) {
                    details.title = config['titleNorthKey'];
                    // details.description = config['descriptionNorthKey'];
                } else {
                    details.title = config['titleSouthKey'];
                    // details.description = config['descriptionSouthKey'];
                }

                details.imageSide = $user.user.side;
            }

            details.showOneTimeOnly = config['showOneTimeOnly'];
            details.showTimer = config['showTimer'];
        });

        return details;
    }

    async function getDailyDealPackDetails() {
        let details = {};

        await getUnityPackConfig().then((config) => {
            details.showOneTimeOnly = config['showOneTimeOnly'];
            details.showTimer = config['showTimer'];
        });

        return details;
    }

    async function displayStoreSection() {
        switch (storeSection) {
            case 'promos':
            case 'luckyDraw':
            case 'beginnerPacks':
            case 'welcomeBackPacks':
            case 'midweekMadness':
            case 'vip':
            case 'featured':
            case 'webshopExclusive':
                await getPromoPackDetails().then((data) => {
                    packDetails = data;
                    packDetails.description = $_('PacksDescription', {
                        values: {
                            GOLD_VALUE: $number(goldWorth),
                        },
                    });
                    packDetails.subTitle = `${$_('PacksReceiveGold')} ${$number(
                        goldAmount,
                    )} ${$_('Gold')}`;
                });

                break;
            case 'dailyDeals':
                await getPromoPackDetails().then((data) => {
                    packDetails = data;
                    packDetails.description = $_('PacksDescription', {
                        values: {
                            GOLD_VALUE: $number(goldWorth),
                        },
                    });
                    packDetails.subTitle = `${$_('PacksReceiveGold')} ${$number(
                        goldAmount,
                    )} ${$_('Gold')}`;
                });

                break;

            case 'goldRush':
                packDetails = {
                    title: $_('NewShopTabEventRecharge'),
                    description: $_('NewShopTabEventRecharge'),
                    subTitle: $_('NewShopTabEventRecharge'),
                };
                break;

            case 'goldPacks':
                packDetails = {
                    title: `${$number(goldAmount)} ${$_('Gold')}`,
                    description: $_('PacksDescription'),
                    subTitle: `${$number(goldAmount)} ${$_('Gold')}`,
                };
                break;

            case 'resources':
                packDetails = {
                    title:
                        storePack.specialTitle.replace('subscription', 'Subscription') +
                        'Title',
                    description:
                        storePack.specialTitleDescription.replace(
                            'subscription',
                            'Subscription',
                        ) + 'Description',
                    subTitle:
                        storePack.specialTitle.replace('subscription', 'Subscription') +
                        'Title',
                };
                break;

            case 'giftFriend':
                if (storePack.holidayTheme === 'winter') {
                    packDetails = {
                        title: $_('GiftPackWinter'),
                        description: $_('GiftPackDescriptionWinter', {
                            values: {
                                AMOUNT: priceString,
                            },
                        }),
                        subTitle: $_('GiftPackWinter'),
                    };
                } else {
                    packDetails = {
                        title: priceString + ' ' + $_('GiftPack'),
                        description: storePack.itemsForAFriend[0].amount > 1 ? $_('GiftPackMultipleDescription') : $_('GiftPackDescription'),
                        subTitle: priceString + ' ' + $_('GiftPack'),
                    };
                }
                break;

            default:
                packDetails = {
                    title: storePack.specialTitle,
                    description: storePack.specialTitleDescription,
                    subTitle: storePack.specialTitle + 'Title',
                };
        }
    }

    function buyPack() {
        dispatch('buyPack', {
            storePack: storePack,
            packDetails: packDetails,
        });
    }

    function setActiveGiftFriendPack() {
        giftFriend.set(
            {
                startPurchase: false,
                selectedPack: storePack,
                selectedPackDetails: packDetails,
                message: '',
            });
    }

    giftFriend.subscribe(value => {
        if (value.startPurchase) {
            dispatch('buyPack', {
                storePack: $giftFriend.selectedPack,
                packDetails: $giftFriend.selectedPackDetails,
            });
        }
    });

    locale.subscribe(displayStoreSection);

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;
    $: packRarity = getPackRarity(rarity);
</script>

{#if $gameConfig}
    {#await $gameConfig.gameItems then gameItems}
        {#if packDetails}
            <div class="pack {storeSection !== 'resources' && storeSection !== 'goldPacks' ? packRarity: ''} {storeSection}">
                <!--                <img class="snow1" src="images/snow.png" alt=""/>-->
                <!--                 {console.log(packDetails)} -->
                {#if packDetails.showOneTimeOnly}
                    <Ribbon>{$_('OneTimeOnlyPromoText')}</Ribbon>
                {/if}
                {#if storeSection !== 'goldAndResources' && storeSection !== 'goldRush'}
                    {#if storePack.showBonus}
                        <Starburst>
                            {Math.floor((goldWorth + goldAmount + goldBonus) / (goldAmount + goldBonus)) * 100}%
                        </Starburst>
                    {/if}
                {/if}
                <div class="content">
                    {#if storeSection !== 'goldPacks' && storeSection !== 'subscriptions' && storeSection !== 'giftFriend'}
                        <div class="header">
                            {#if storePack.purchased}
                                <h2>Purchased</h2>
                            {/if}
                            <h2>{$_(packDetails.title)}</h2>
                            <span class="shadow"></span>
                            {#if storeSection === 'promos' || storeSection === 'beginnerPacks' || storeSection === 'featured'}
                                <picture>
                                    <source srcset="{amazon_pack_image_url}/{storePack.bundleName}/image{packDetails.imageSide}.webp"
                                            type="image/webp">
                                    <source srcset="{amazon_pack_image_url}/{storePack.bundleName}/image{packDetails.imageSide}.jpg"
                                            type="image/jpeg">
                                    <img
                                            class="pack-image"
                                            src="{amazon_pack_image_url}/{storePack.bundleName}/image{packDetails.imageSide}.jpg"
                                            alt={$_(packDetails.title)}
                                    />
                                </picture>
                            {:else}
                                <picture>
                                    <source srcset="{amazon_pack_image_url}/{storePack.bundleName}/image.webp"
                                            type="image/webp">
                                    <source srcset="{amazon_pack_image_url}/{storePack.bundleName}/image.jpg"
                                            type="image/jpeg">
                                    <img
                                            class="pack-image"
                                            src="{amazon_pack_image_url}/{storePack.bundleName}/image.jpg"
                                            alt={$_(packDetails.title)}
                                    />
                                </picture>
                            {/if}
                        </div>
                    {/if}
                    <div class="sub-header">
                        {#if storeSection === 'giftFriend'}
                            {#if storePack.holidayTheme === 'winter'}
                                <picture class="gift-pack-icon">
                                    <source srcset={giftPackWinterIconUrl[0]} type="image/webp">
                                    <source srcset={giftPackWinterIconUrl[1]} type="image/jpg">
                                    <img alt="Winter Gift Pack Icon" src={giftPackWinterIconUrl[0]} width="150px">
                                </picture>
                            {:else}
                                <picture class="gift-pack-icon">
                                    <source srcset={giftPackIconUrl[0]} type="image/webp">
                                    <source srcset={giftPackIconUrl[1]} type="image/jpg">
                                    <img alt="Gift Pack Icon" src={giftPackIconUrl[0]} width="150px">
                                </picture>
                            {/if}
                        {/if}
                        <h3 class="{storePack.extraGold ? 'extraGold' : ''}">{$_(packDetails.subTitle)}</h3>
                        {#if storePack.extraGold}
                            <div class="extraGold animate__animated animate__shakeY animate__delay-2s">
                                <span>+{$number(storePack.gold)} {$_('more')}</span>
                            </div>
                        {/if}
                        {#if storeSection === 'resources'}
                            <h4>
                                {`${$_('PacksReceiveGold')} ${$number(goldAmount)} ${$_('Gold')}`}
                            </h4>
                            <span class="shadow"></span>
                            <small>
                                {$_('NewShopValidFor')}
                                {storePack.dailyRewards.days}
                                {$_('Days')}
                            </small>
                        {/if}

                        {#if storeSection !== 'goldPacks' || storePack.goldWorth}
                            <p>
                                {@html $_(packDetails.description, {
                                    values: {GOLD_VALUE: $number(goldWorth)},
                                })}
                            </p>
                        {/if}
                    </div>

                    <ItemsListing storePack={storePack} goldAmount={goldAmount} globals={globals} items={items}/>

                </div>
                {#if storePack.endAt}
                    <Countdown endDate={storePack.endAt}>
                        {$_('IncrementalPacksTimeLeft')}
                    </Countdown>
                {/if}

                {#if storeSection !== 'giftFriend'}
                    <Button primary class="buy-button orange" on:click={buyPack}>
                        <!--                    <img class="snow3" src="images/snow2.png" alt=""/>-->
                        {#if storeSection === 'luckyDraw' && $luckyDrawEvent.availableDiscount}
                            <img alt="Discount" class="lucky-draw-discount"
                                 src="/images/lucky_draw/badge/red_discount_{$luckyDrawEvent.availableDiscount}.png"/>
                        {:else if storePack.webshopDiscount > 0 && storeSection !== 'luckyDraw'}
                            <img alt="Discount" class="lucky-draw-discount"
                                 src="/images/lucky_draw/badge/red_discount_{storePack.webshopDiscount}.png"/>
                        {:else}
                            <img alt="Discount" class="lucky-draw-discount"
                                 src="/images/lucky_draw/badge/red_discount_10.png"/>
                        {/if}
                        {#if storePack.paymentHub.fullPrice.amount !== 'missing' && storePack.paymentHub.fullPrice.currency !== 'missing'}
                            <s>
                                {$number(
                                    storePack.paymentHub.fullPrice.amount / 100,
                                    {
                                        style: 'currency',
                                        currency: storePack.paymentHub.fullPrice.currency,
                                    },
                                )}
                            </s>
                        {:else}
                            Price or currency missing
                        {/if}
                        {#if storePack.paymentHub.price.amount !== 'missing' && storePack.paymentHub.price.currency !== 'missing'}
                            {$number(storePack.paymentHub.price.amount / 100, {
                                style: 'currency',
                                currency: storePack.paymentHub.price.currency,
                            })}
                        {:else}
                            Price or currency missing
                        {/if}
                    </Button>
                {:else}
                    <Button primary class="buy-button orange" on:click={setActiveGiftFriendPack}>
                        {#if storePack.paymentHub.price.amount !== 'missing' && storePack.paymentHub.price.currency !== 'missing'}
                            {$number(storePack.paymentHub.price.amount / 100, {
                                style: 'currency',
                                currency: storePack.paymentHub.price.currency,
                            })}
                        {:else}
                            Price or currency missing
                        {/if}
                    </Button>
                {/if}
            </div>
        {/if}
    {:catch e}
        Error resolving promise
    {/await}
{/if}

<style lang="scss">
  @import '../styles/vars.scss';

  :global {
    .missing {
      border: 2px solid greenyellow;
      background-color: black;
      color: greenyellow;
      font-family: monospace;
      font-size: 12px;
      padding: 5px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .pack {
      background-color: black;
      padding: 15px 10px 15px 10px;
      margin: auto;
      height: 100%;
      width: 100%;
      color: white;
      position: relative;
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      box-shadow: inset 0px 0px 10px 3px rgb(0 0 0 / 5%);
      border-radius: $store-pack-border-radius;
      z-index: 1;

      .lucky-draw-discount {
        position: absolute;
        left: 5px;
        bottom: -17px;
        width: 55px;
        transform: rotate(-10deg);
      }

      .timer {
        text-align: center !important;
        display: inline-block;
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.7);
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        transition: opacity 0.4s;
        margin-bottom: -12px;
      }

      &.goldPacks {
        background: #ffdc6a;
        border: $store-pack-border-width solid #f7e3ad;
        height: $store-pack-height-gold-packs;

        .sub-header {
          background: none !important;
          box-shadow: none !important;
          top: 0;
          color: #531f0d;
          overflow: visible;
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);

          h3 {
            font-size: 26px;
            line-height: 28px;
            color: #531f0d;
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
            margin-top: 5px;
            padding: 5px 0;

            &.extraGold {
              position: relative;
              top: -10px;
            }
          }

          div.extraGold {
            position: absolute;
            top: -23px;
            width: 285px;
            height: 205px;
            background: url('/images/webshop_first_purchase_bonus_plate.png') no-repeat 0 0;
            background-size: contain;
            z-index: -1;

            span {
              width: 100%;
              position: absolute;
              top: 68px;
              left: 0;
              font-size: 22px;
              font-family: var(--font-family-sans-condensed);
              color: rgb(255, 255, 255);
              font-weight: bold;
              text-shadow: 0 0 4px rgba(0, 0, 0, 0.63);
            }

            @media (max-width: 700px) {
              top: -25px;
              width: 285px;

              span {
                top: 68px
              }
            }

            @media (max-width: 500px) {
              top: -40px;
              width: 350px;

              span {
                top: 87px
              }
            }
          }
        }
      }

      &.resources {
        height: $store-pack-height-resources;
        background: #ffdc6a;
        border: $store-pack-border-width solid #f7e3ad;

        h2 {
          display: none;
        }

        $headerOffset: 60px;

        .sub-header {
          margin-top: -$headerOffset;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          z-index: 10;
          height: $store-pack-height-resources - $store-pack-header-height + $headerOffset;
          border-bottom-left-radius: $store-pack-border-radius;
          border-bottom-right-radius: $store-pack-border-radius;
          background: $legendaryMainColor;
          padding-bottom: 88px;
          background: radial-gradient(
                          ellipse at center,
                          #ffb92d 10%,
                          saturate($legendaryMainColor, 40%) 100%
          );

          .shadow {
            width: 100%;
            height: 60px;
            position: absolute;
            z-index: 12;
            background-color: rgba(0, 0, 0, 0.2);
            left: 0;
            bottom: 28px;
          }

          small {
            position: absolute;
            width: 100%;
            bottom: 5px;
            left: 0;
          }

          h3 {
            font-size: 22px;
            line-height: 26px;
            color: #531f0d;
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
            padding: 10px 0;
          }

          h4 {
            margin: 0;
            font-weight: 700;
            font-family: var(--font-family-sans-condensed);
          }

          p {
            margin-top: 5px;
            color: #531f0d;
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
          }
        }
      }

      &.giftFriend {
        height: 410px;

        .content {
          width: 100%;

          .sub-header {
            margin-top: -5px;
            height: 100px;
            top: 0;
            overflow: visible;

            .gift-pack-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: -75px;
            }
          }

          .items {
            margin-top: 75px;

            .mask {
              height: 213px;
            }
          }
        }

        .buy-button {
          height: 52px;
        }
      }

      &.common {
        background: #b4e56f;
        border: $store-pack-border-width solid #daf5ab;

        .sub-header {
          background: $commonMainColor;
          background: radial-gradient(
                          ellipse at center,
                          #d4dd1e 10%,
                          saturate($commonMainColor, 40%) 100%
          );
        }
      }

      &.rare {
        background: #76cdfe;
        border: $store-pack-border-width solid #a1f2f5;

        .sub-header {
          background: $rareMainColor;
          background: radial-gradient(
                          ellipse at center,
                          #23b6da 10%,
                          saturate($rareMainColor, 40%) 100%
          );
        }
      }

      &.epic {
        background: #f4c7ff;
        border: $store-pack-border-width solid #ffdbff;

        .sub-header {
          background: $epicMainColor;
          background: radial-gradient(
                          ellipse at center,
                          #ff5acf 10%,
                          saturate($epicMainColor, 40%) 100%
          );
        }
      }

      &.legendary {
        background: #ffdc6a;
        border: $store-pack-border-width solid #f7e3ad;

        .sub-header {
          background: $legendaryMainColor;
          background: radial-gradient(
                          ellipse at center,
                          #ffb92d 10%,
                          saturate($legendaryMainColor, 40%) 100%
          );
        }
      }

      .header {
        position: absolute;
        top: -$store-pack-border-width;
        left: -$store-pack-border-width;
        right: -$store-pack-border-width;
        height: $store-pack-header-height;
        overflow: hidden;
        box-shadow: inset 0 1px 18px 0 #ffffff;
        border-radius: $store-pack-border-radius $store-pack-border-radius 0 0;
        background: $dark-color;

        .shadow {
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
          box-shadow: inset 0 5px 15px 0 #ffffff,
          inset 0px 1px 0px 0px rgb(255 255 255 / 65%);
          z-index: 3;
          border-radius: $store-pack-border-radius $store-pack-border-radius 0 0;
          background-size: cover;
        }

        .pack-image {
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          z-index: 2;
          background: darken($dark-color, 2%);
          object-fit: cover;
          object-position: center top;
          width: 100%;
          height: $store-pack-header-height;
        }

        h2 {
          z-index: 35;
          position: absolute;
          font-weight: 700;
          font-family: var(--font-family-sans-condensed);
          text-align: center;
          left: 0;
          right: 0;
          bottom: 10px;
          padding: 0 15px;
          margin: 0;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7), 0 1px 0 rgba(0, 0, 0, 0.3);
          font-size: 16px;
          line-height: 20px;
          cursor: default;
        }
      }

      .sub-header {
        position: absolute;
        top: $store-pack-header-height - $store-pack-border-width;
        left: -$store-pack-border-width - 3;
        right: -$store-pack-border-width - 3;
        overflow: hidden;
        height: 60px;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
        box-shadow: 0 5px 30px 0 rgb(0 0 0 / 35%),
        inset 0 0 22px 0 rgb(0 23 53 / 32%),
        inset 0px 1px 0px 0px rgb(255 255 255 / 45%),
        inset 0px -2px 2px 1px rgb(0 0 0 / 45%);
        border-radius: 3px;
        padding: 0 $store-pack-border-width * 4 - 5;
        text-align: center;
        font-family: var(--font-family-sans-condensed);
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        z-index: 15;
        cursor: default;

        h3 {
          font-family: var(--font-family-sans-condensed);
          font-size: 18px;
          line-height: 24px;
          font-weight: 700;
          margin: 0;
          color: #feffd6;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          padding: 0;
          line-height: 14px;
        }
      }
    }

    .resources {
      .claim-button {
        width: 100%;
        align-self: flex-end;
        z-index: 15 !important;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 2.5rem !important;
      }
    }

    .buy-button {
      width: 100%;
      align-self: flex-end;
      z-index: 15 !important;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 2.5rem !important;

      s,
      strike {
        font-size: 14px;
        text-decoration: none;
        position: relative;
        color: rgba(0, 0, 0, 0.65);

        &:before {
          top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
          background: red; /*this is the color of the line*/
          opacity: 0.7;
          content: '';
          width: 110%;
          position: absolute;
          height: 0.1em;
          border-radius: 0.1em;
          left: -5%;
          white-space: nowrap;
          display: block;
          transform: rotate(-15deg);
        }

        &.straight::before {
          transform: rotate(0deg);
          left: -1%;
          width: 102%;
        }
      }
    }

    .packGameToken {
      &:after {
        content: "";
        background: url('/images/items/packGameToken.png');
        width: 40px;
        height: 40px;
        background-size: cover;
        position: relative;
        bottom: 1px;
      }
    }
  }
</style>
